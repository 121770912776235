@font-face {
  font-family: "Inter-Black";
  src: url("./fonts/Inter-Black.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("./fonts/Inter-ExtraBold.ttf");
}
@font-face {
  font-family: "Inter-ExtraLight";
  src: url("./fonts/Inter-ExtraLight.ttf");
}
@font-face {
  font-family: "Inter-Light";
  src: url("./fonts/Inter-Light.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("./fonts/Inter-Thin.ttf");
}

html {
  font-size: 62.5%;
}

html,
body,
#root,
.App {
  height: 100%;
}

img {
  display: inline-block !important;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.ant-modal {
  padding-bottom: 0 !important;
}

.darkModal > .ant-modal-content {
  background-color: #051f31 !important;
  backdrop-filter: blur(50px);
  border-radius: 16px !important;
  border: none;
}

.lightModal > .ant-modal-content {
  background-color: #fff !important;
  backdrop-filter: blur(50px);
  border-radius: 16px !important;
  border: none;
}

.darkModal .ant-modal-header {
  padding: 24px !important;
  border-radius: 16px;
  background-color: #051f31 !important;
}

.lightModal .ant-modal-header {
  padding: 24px !important;
  border-radius: 16px;
  background-color: #fff !important;
}

.ant-modal-header,
.ant-modal-footer {
  border: none !important;
}

#delegate_amount {
  height: 60px;
  border-radius: 16px;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 20px;
}

.lightModal #delegate_amount {
  color: #0f68aa !important;
  background-color: #fff;
  border: 1px solid #0f68aa !important;
}

.darkModal #delegate_amount {
  color: #2fbdf4 !important;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #2fbdf4 !important;
}

#delegate_amount:hover {
  border: 1px solid #2fbdf4 !important;
}

.ant-modal-body {
  padding-top: 0px !important;
  text-align: center;
}

.statusModal {
  top: 25% !important;
}

.statusModal > .ant-modal-close-x {
  color: #868686;
}

.anticon svg {
  color: #868686;
}

.noticeSuccess_dark {
  border-radius: 12px !important;
  border: none;
  background: #072e49 !important;
  color: #666666;
  padding: 10px !important;
  /* backdrop-filter: blur(10px); */
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);
}

.noticeSuccess_light {
  border-radius: 12px !important;
  border: none;
  background: #fff;
  color: #666666;
  padding: 10px !important;
  /* backdrop-filter: blur(10px); */
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);
}

.ant-notification-notice-message {
  color: #33ba59 !important;
}

.ant-notification-notice-icon {
  top: 22px;
}

.pro-sidebar .overlay {
  background-color: initial !important;
}

.ant-btn > .ant-btn-loading-icon {
  position: relative;
  top: -3px;
}
